export class TextAnim {
  constructor(text, duration, fixLines = false) {
    this.text = text;
    this.duration = duration;
    this.fixLines = fixLines;
    this._currentText = '';
    this._index = 0;
    this._lineCount = 0;
    this._RL = this.getRowLength();
    this._RC = this.getRowCount();
  }

  step() {
    if(this._index < this.text.length) {
      this._index++;
      this._currentText = this.text.substr(0, this._index);

      /**
       * Fill up first row
       */
      if(this._index < this._RL) {
        const numPad = this._RL - this._index - 1;
        const padText = ' '.repeat(numPad);
        this._currentText = this._currentText + padText;
      }

      // /**
      //  * Pad lines
      //  */
      // if (this._index % this._RL == 0) {
      //   console.log(this._lineCount);
      //   this._lineCount++;
      // }
      // const numLines = this._RC - this._lineCount;
      // const linePad = "\n-".repeat(numLines);
      // console.log(numLines, linePad);
      // this._currentText += linePad;

      return true;
    }
    return false;
  }

  getRowLength() {
    const row = this.text.split('\n')[0];
    return row.length;
  }

  getRowCount() {
    return this.text.split('\n').length;
  }

  get currentText() {
    return this._currentText;
  }
}
