export const { floor, abs } = Math;

/**
 * @param {Length of string} text
 * @returns integer
 */
export const len = (text) => text.length;

/**
 * @param {Text to repeat} text
 * @param {Repeat times} times
 * @returns string
 */
export const srep = (text, times) => text.repeat(times);

export const closedOffLine = (text, width) => {
  const pad = abs(width - len(text));
  return text + srep(' ', pad) + '│';
}

export function throttle(callback, delay) {
  let sent = false;
  let id = null;
  return function() {
    if (!sent) {
      callback();
      sent = true;
    } else {
      if (!id) {
        id = setTimeout(() => {
            id = null;
            sent = false;
        }, delay);
      }
    }
  }
}


/**
 * Generate a random progress bar and let it go through
 */
export function getRandomLoadingMessage() {
  const messages = [
      'taking out the trash',
      'rolling a joint',
      'composing a song',
      'throwing paper planes',
      'ordering pizza',
      'hacking NASA',
      'talking to Mom',
      'pouring wine',
      'headbanging',
      'buying airplane tickets',
      'setting things on fire',
      'petting a crocodile',
      'talking to HQ',
      'hacking YouTube',
      'watching grass grow',
      'popping a beer',
      'denying existence',
      'collecting stones',
      'compressing 3D video',
      'deploying SkyNet',
      'plotting a conspiracy',
      'altering reality',
      'saving humanity',
      'charging batteries',
      'warming the reactor',
      'seeking help',
      'watching tutorials',
      'procrastinating',
      'eating pickles',
  ];
  const randIndex = Math.floor(Math.random() * messages.length);
  return messages[randIndex];
}

export const getProgressBarText = (title, percent) => {
  const WIDTH = 31;
  const C_EMPTY = '▓';
  const C_FULL = '░';
  const progressCount = floor((percent / 100) * WIDTH);
  const leftSide = C_EMPTY.repeat(progressCount);
  const rightSide = C_FULL.repeat(WIDTH - progressCount);
  // substitutions
  const progress = leftSide + rightSide;
  const paddedTitle = title + ' '.repeat(42 - len(title)) +  '│';

  const PROGRESS_BAR_TEXT = `
┌───────────────────────────────────────────┐
│ HumanMusic                                │
├───────────────────────────────────────────┤
│ ${paddedTitle}
│                                           │
│ Progress: ${progress} │
└───────────────────────────────────────────┘
`
  return PROGRESS_BAR_TEXT;
}
