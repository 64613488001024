import PubSub from 'pubsub-js';
import { createPlayerObject } from "./components/player";
import { generateAPITag } from "./utils/youtubeApi";
import Axios from 'axios';
import { Terminal } from './components/terminal';
import './utils/keyboardEvents';
import { toggleFullScreen } from './utils/keyboardEvents';
import { LOGO_TEXT } from './data/text-components';
import { GENERIC_EVENTS, TERMINAL_EVENTS, UI_EVENTS, YT_EVENTS } from './components/event-constants';
import { isThisQuarter } from 'date-fns';

const TUI = new Terminal();
console.log(TUI);

/**
 * HumanMusic main class
 * ---------------------
 * author: Tibor Szasz
 * 2019-05-05
 * Budapest
 */
class App {
    constructor() {
        this.currentPlayer = 'trackA';
        this.players = {};
        this.trackData = null;
        this.firstPlay = false;

        this.handlePlayerErrorEvent();
        this.subscribePlayerEvents();
        this.timeCheckLoop();
        this.fetchTrackState();
        this.addUIEvents();


        document.querySelector('#logo').textContent = LOGO_TEXT;
        console.log(LOGO_TEXT);
    }

    // Always return the currently active player
    get player() {
        return this.players[this.currentPlayer];
    }

    revealUI() {
        document.querySelector('#ui').classList.add('fadeIn');
        document.querySelector('#youtube-logo').classList.remove('hidden');
    }

    fetchTrackState() {
        const handleResponse = response => {
            if (this.trackData) {
                if (response.data.nextTrack.ytid === this.player.id()) {
                    console.log('CURRENT TRACK IS SAME AS NEXT, RETRYING...');
                    setTimeout(() => {
                        this.fetchTrackState();
                    }, 2000);
                }
            }
            this.trackData = response.data;
            this.setCurrentPlayer();
        };
        const host = window.location.host.indexOf('localhost') === -1 ? '' : 'http://localhost:3003';
        Axios.get(host + '/api/v1.0/tracks?' + new Date().getTime()).then(handleResponse);
    }

    setCurrentPlayer() {
        const id = this.trackData.currentTrack.ytid;
        const startAt = this.trackData.currentTrack.elapsed;

        if (!this.player) {
            this.players[this.currentPlayer] = createPlayerObject(this.currentPlayer, id, startAt);

            // Initialize second player with a 1 second video: 7-qGKqveZaM
            setTimeout(() => {
                this.players['trackB'] = createPlayerObject('trackB', '7-qGKqveZaM', 0);
            }, 2000);
        }
    }

    switchPlayer() {
        this.currentPlayer = this.currentPlayer === 'trackA' ? 'trackB' : 'trackA';
    }

    getOppositePlayer() {
        return this.currentPlayer === 'trackA' ? 'trackB' : 'trackA';
    }

    moveCurrentPlayerToTop() {
        document.querySelectorAll('main iframe').forEach((track) => {
            track.style.zIndex = 0;
            if (track.id === this.currentPlayer) {
                window.setTimeout(() => {
                    track.classList.add('fadeIn');
                    track.style.zIndex = 1;
                });
            } else {
                window.setTimeout(() => {
                    track.classList.remove('fadeIn');
                }, 2000);
            }
        });
    }

    getCurrentTrackMetaByYtId(ytid) {
        const currentKey = this.trackData.currentTrack.ytid === ytid ? 'currentTrack' : 'nextTrack';
        return this.trackData[currentKey];
    }

    setTabTitle(ytid) {
        const {
            title,
            artist
        } = this.getCurrentTrackMetaByYtId(ytid);
        document.title = `${title} by ${artist} | Human Music TV`;
    }

    setYouTubeLink(ytid) {
        document.querySelector('#youtube-logo').href = `https://youtube.com/watch?v=${ytid}`;
    }

    togglePlayerMuting() {
        this.players['trackA'].toggleMute();
        if (this.players['trackB']) {
            this.players['trackB'].toggleMute();
        }
    }

    toggleMuteLabel() {
        const muteLabel = document.querySelector('nav .mute');
        muteLabel.textContent = muteLabel.textContent === ' mute [space]' ? ' unmute [space]' : ' mute [space]';
    }

    addUIEvents() {
        document.querySelector('nav .fullscreen').addEventListener('click', toggleFullScreen);
        document.querySelector('nav .mute').addEventListener('click', () => {
            this.togglePlayerMuting();
            this.toggleMuteLabel();
        });
        document.body.addEventListener('dblclick', () => {
            toggleFullScreen();
        });
    }

    handlePlayerErrorEvent() {
        const ErrorCodes = {
            2: 'API ERROR: invalid parameter values',
            5: 'Client error: no HTML5 video support',
            101: 'No embed error',
            100: 'Video unavailable (legal or by uploader)',
            150: 'Video unavailable (legal or by uploader)',
        }

        PubSub.subscribe(YT_EVENTS.ERROR, (event, error_code) => {
            console.table("ERROR EVENT:", event, error_code);

            if (ErrorCodes[error_code]) {
                console.log('Track failed to load:', this.getCurrentTrackMetaByYtId(this.player.id()));
                PubSub.publish(TERMINAL_EVENTS.SHOW_ERROR, ErrorCodes[error_code]);
                return;
            }
            PubSub.publish(TERMINAL_EVENTS.SHOW_ERROR, "UNKNOWN");
        });
    }

    subscribePlayerEvents() {
        PubSub.subscribe(YT_EVENTS.READY, (event, payload) => {
            const { nodeId, videoDetails } = payload;
            const { ytid, startAt } = videoDetails;
            console.log('Player ready', nodeId, this);

            if (!this.firstPlay) {
                console.log('First play on', nodeId);
                this.player.setVideoId(ytid, startAt).then(() => {
                    console.log('should play');
                    this.player.play();
                });
                this.firstPlay = true;
            }
        });

        PubSub.subscribe(YT_EVENTS.ENDED, (_event) => {
            window.setTimeout(() => {
                this.fetchTrackState();
            }, 16);
        });

        PubSub.subscribe(YT_EVENTS.PLAYING, (_event, { nodeId, ytid, warmingUp }) => {
            if (warmingUp) {
                return;
            }
            console.log('Player playing', nodeId);
            this.moveCurrentPlayerToTop();
            this.setTabTitle(ytid);
            this.setYouTubeLink(ytid);

            if (this.firstPlay) {
                this.revealUI();
                console.log('First play for video', ytid);
                PubSub.publish(TERMINAL_EVENTS.HIDE);
            }
        });

        PubSub.subscribe(YT_EVENTS.SHOULD_SHOW_TITLE, (_event, ytid) => {
            console.log('SHOULD_SHOW_TITLE', ytid);
            if (ytid) {
                const meta = this.getCurrentTrackMetaByYtId(ytid);
                PubSub.publish(TERMINAL_EVENTS.SHOW_TITLE, meta);
            }
        });

        PubSub.subscribe(YT_EVENTS.SHOULD_HIDE_TITLE, (_event, ytid) => {
            console.log('Should hide title', ytid);
            PubSub.publish(TERMINAL_EVENTS.HIDE);
        });

        PubSub.subscribe(YT_EVENTS.CROSSFADE, (_event, id) => {
            console.log('Crossfading', id);
            this.switchPlayer();
            this.player.burst();
        });

        PubSub.subscribe(YT_EVENTS.VIDEO_CUTOFF, (event, id) => {
            console.log('cutoff time', id);
            if (id === this.currentPlayer) {
                const id = this.trackData.nextTrack.ytid;
                const oppositePlayerId = this.getOppositePlayer();
                let oppositePlayer = this.players[oppositePlayerId];

                console.log('changing video for player', oppositePlayerId);
                oppositePlayer.setVideoId(id, 0).then(() => {
                    oppositePlayer.warmup();
                });
            }
        });

        PubSub.subscribe('MUTE_PLAYER_TOGGLE', (event, id) => {
            this.toggleMuteLabel();
            this.togglePlayerMuting();
        });
    }

    timeCheckLoop() {
        setTimeout(() => {
            if (this.player) {
                this.player.tick();
            }
            this.timeCheckLoop();
        }, 100);
    }
}

/**
 * Set the global callback for YT API load event
 */
window.onYouTubeIframeAPIReady = () => {
    window.app = new App()
}

PubSub.subscribe(GENERIC_EVENTS.INIT_APPLICATION, () => {
    /**
     * Start human music
     */
    generateAPITag();
});
