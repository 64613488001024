import PubSub from 'pubsub-js';
import { YT_EVENTS } from './event-constants';
import { throttle } from './utils';

// seconds
const END_CUTOFF = 16; // default 16
const TITLE_DELAY = 16; // default 16
const TITLE_DURATION = 8;
const CROSSFADE_LENGTH = 2;

const { round, floor } = Math;

export function createPlayerObject(nodeId, initalVideoId, startAt) {
    console.log('creating player object', nodeId);

    /**
     * Timing info on current player only
     */
    let startTime = startAt;
    let videoDuration = 0;
    let currentTime = 0;
    let videoPlaying = false;
    let cutoffSent = false;
    let warmingUp = false;
    let ytid = initalVideoId;

    const playerOptions = {
        videoId: ytid,
        playerVars: {
            controls: 0,
            disablekb: 0,
            modestbranding: 1,
            autohide: 0,
            rel: 0,
            showinfo: 0,
            loop: 0,
            iv_load_policy: 3,
        }
    };

    let MUTED = false;
    const Player = new YT.Player(nodeId, playerOptions);

    let throttledShowTitle = throttle(() => {
        broadcactShowTitle(ytid);
    }, 2500);

    let throttledHideTitle = throttle(() => {
        broadcactHideTitle();
    }, 2500);

    Player.addEventListener('onReady', (event) => {
        /**
         * Workaround to fix not firing YouTube onError event
         * this makes sure that the event fires.
         * Remove when bug is fixed in YT api. // haha
         */
        Player.loadVideoById(ytid);

        PubSub.publish(YT_EVENTS.READY, {
            nodeId,
            videoDetails: {
                startAt,
                ytid: initalVideoId,
            }
        });
        console.log('Published', YT_EVENTS.READY, ytid);
    });

    Player.addEventListener('onError', (event) => {
        // 2 – The request contains an invalid parameter value.
        //     For example, this error occurs if you specify a video ID that does not have 11 characters,
        //     or if the video ID contains invalid characters, such as exclamation points or asterisks.
        // 5 – The requested content cannot be played in an HTML5 player or another error related to the HTML5 player has occurred.
        // 100 – The video requested was not found. This error occurs when a video has been removed (for any reason)
        //        or has been marked as private.
        // 101 – The owner of the requested video does not allow it to be played in embedded players.
        // 150 – This error is the same as 101. It's just a 101 error in disguise!
        console.log("Error event from player", event);
        PubSub.publish(YT_EVENTS.ERROR, event.data);
    });

    Player.addEventListener('onStateChange', (event) => {
        if (event.data === YT.PlayerState.PLAYING) {
            videoDuration = Player.getDuration();
            PubSub.publish(YT_EVENTS.PLAYING, { nodeId, ytid, warmingUp });
            videoPlaying = true;
        }

        if (event.data === YT.PlayerState.PAUSED) {
            PubSub.publish(YT_EVENTS.PAUSED, nodeId);
            videoPlaying = false;
        }

        if (event.data === YT.PlayerState.ENDED) {
            PubSub.publish(YT_EVENTS.ENDED, nodeId);
            videoPlaying = false;
        }

        if (event.data === YT.PlayerState.CUED) {
            PubSub.publish(YT_EVENTS.CUED, nodeId);
            videoPlaying = false;
        }

        if (event.data === YT.PlayerState.BUFFERING) {
            PubSub.publish(YT_EVENTS.BUFFERING, nodeId);
            videoPlaying = false;
        }
    });

    function id() {
        return ytid;
    }

    function isPlaying() {
        return videoPlaying;
    }

    function isVideoAboutToEnd() {
        return (videoDuration - currentTime) < END_CUTOFF;
    }

    function shouldCrossfadeStart() {
        return (videoDuration - currentTime) < CROSSFADE_LENGTH;
    }

    function shouldTitleShow() {
        return round(currentTime) === TITLE_DELAY;
    }

    function shouldTitleHide() {
        return round(currentTime) === TITLE_DELAY + TITLE_DURATION;
    }

    function shouldEndTitleShow() {
        const showEndTitleAt = videoDuration - (TITLE_DELAY + TITLE_DURATION);
        return round(currentTime) === round(showEndTitleAt);
    }

    function shouldEndTitleHide() {
        const hideEndTitleAt = videoDuration - (TITLE_DELAY);
        return round(currentTime) === round(hideEndTitleAt);
    }

    function broadcactShowTitle(ytid) {
        PubSub.publish(YT_EVENTS.SHOULD_SHOW_TITLE, ytid);
    }

    function broadcactHideTitle(ytid) {
        PubSub.publish(YT_EVENTS.SHOULD_HIDE_TITLE, ytid);
    }

    function play() {
        Player.playVideo();
        return new Promise((resolve, reject) => {
            PubSub.subscribe(YT_EVENTS.PLAYING, (event, playerInfo) => {
                if (playerInfo.nodeId === nodeId) {
                    resolve();
                }
            });
        });
    }

    function pause() {
        Player.pauseVideo();
    }

    function setVideoId(_ytid, startAt = 0) {
        cutoffSent = false;
        startTime = startAt;
        ytid = _ytid;
        Player.cueVideoById(_ytid, startAt, 'large');

        return new Promise((resolve, reject) => {
            PubSub.subscribe(YT_EVENTS.CUED, (event, id) => {
                console.log('Cue video is ready for', nodeId);
                if (id === nodeId) {
                    console.log('Resolving ready after id cue', id);
                    resolve();
                }
            });
        });
    }

    function toggleMute() {
        if (!MUTED) {
            Player.setVolume(0);
        } else {
            Player.setVolume(100);
        }
        MUTED = MUTED ? false : true;
    }

    function warmup() {
        warmingUp = true;
        Player.setVolume(0);

        console.log('Warming up track on deck', nodeId);
        play().then(() => {
            pause();
            warmingUp = false;
        });
    }

    function burst() {
        if (!MUTED) {
            Player.setVolume(100);
        }
        play();
    }

    /**
     * Player object API
     */
    return {
        id,
        play,
        pause,
        Player,
        setVideoId,
        isPlaying,
        toggleMute,
        warmup,
        burst,

        tick() {
            if (!videoPlaying) {
                return false;
            }
            currentTime = Player.getCurrentTime();

            if (isVideoAboutToEnd() && videoPlaying && !cutoffSent) {
                PubSub.publish(YT_EVENTS.VIDEO_CUTOFF, nodeId);
                cutoffSent = true;
            }

            if (shouldTitleShow()) {
                throttledShowTitle();
            }

            if (shouldEndTitleShow()) {
                throttledShowTitle();
            }

            if (shouldTitleHide()) {
                throttledHideTitle();
            }

            if (shouldEndTitleHide()) {
                throttledHideTitle();
            }

            if (shouldCrossfadeStart()) {
                PubSub.publish(YT_EVENTS.CROSSFADE);
            }
            return true;
        }
    };
}