import PubSub from 'pubsub-js';

import { GENERIC_EVENTS, TERMINAL_EVENTS } from './event-constants';
import { ScreenManager } from '../tui/lib/ScreenManager';
import { SplashScreen } from '../tui/screens/Splash';
import { ErrorScreen } from '../tui/screens/Error';
import { LoadingScreen } from '../tui/screens/Loading';
import { TitleScreen } from '../tui/screens/Title';
import { SCREEN_NAMES } from '../tui/screens/screens';

export class Terminal {
    constructor() {
        this.root = document.querySelector('#tui');

        this.terminal = this.root.querySelector('#terminal');
        this.screenManager = new ScreenManager(this.terminal);

        // Create all screens
        const splash = new SplashScreen();
        this.screenManager.add(splash);

        const error = new ErrorScreen();
        this.screenManager.add(error);

        const loading = new LoadingScreen();
        this.screenManager.add(loading);

        const title = new TitleScreen();
        this.screenManager.add(title);

        // const data = {
        //     artist: 'Trololo feat. Florence and the Machine - Live KEXP',
        //     title: 'One date in a park with a longer title than fits and a bit more lorem ipsum',
        //     year: 2022,
        // }
        // Init app with splash screen
        this.screenManager.playScreen(SCREEN_NAMES.SPLASH);

        // Enable event bus
        this.initEventListeners();
        this.initGlitchEffect();
    }

    initEventListeners() {
        PubSub.subscribe(TERMINAL_EVENTS.SHOW_ERROR, (_errorMessage, payload) => {
            this.screenManager.show(SCREEN_NAMES.ERROR);
            this.screenManager.playScreen(SCREEN_NAMES.ERROR, payload);
        });

        PubSub.subscribe(GENERIC_EVENTS.INIT_APPLICATION, () => {
            this.screenManager.show(SCREEN_NAMES.LOADING);
            this.screenManager.playScreen(SCREEN_NAMES.LOADING);
        });

        PubSub.subscribe(TERMINAL_EVENTS.SHOW_TITLE, (_, payload) => {
            this.screenManager.show(SCREEN_NAMES.TITLE);
            this.screenManager.playScreen(SCREEN_NAMES.TITLE, payload);
            this.showTerminalNode({ className: 'light-mask' });
        });

        PubSub.subscribe(TERMINAL_EVENTS.HIDE_TITLE, () => {
            console.log('hiding termiunal');
            this.hideTerminalNode();
        });

        PubSub.subscribe(TERMINAL_EVENTS.HIDE, () => {
            console.log('hiding termiunal');
            this.hideTerminalNode();
        });
    }

    initGlitchEffect() {
        this.terminal.classList.add('flicker-effect');

        setInterval(() => {
            this.terminal.classList.add('glitch_2');
            setTimeout(() => {
                this.terminal.classList.remove('glitch_2');
            }, 80);
        }, 13500);
    }

    hideTerminalNode() {
        this.root.classList.add('fadeOut');
        if (this.root.classList.contains('light-mask')) {
            this.root.classList.remove('light-mask');
        }
    }

    showTerminalNode(options) {
        const { className } = options;
        if (className) {
            this.root.classList.add(className);
        }
        this.root.classList.remove('fadeOut');
    }
}
