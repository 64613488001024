export const GENERIC_EVENTS = {
  INIT_APPLICATION: 'init_application'
};

export const TERMINAL_EVENTS = {
  SHOW: 'show',
  HIDE: 'hide',
  SHOW_TITLE: 'show_title',
  HIDE_TITLE: 'hide_title',
  SHOW_ERROR: 'show_error',
  HIDE_ERROR: 'hide_error',
  DISPLAY_SCREEN: 'display_screen',
};

export const YT_EVENTS = {
  READY: 'ready',
  ERROR: 'error',
  VIDEO_CUTOFF: 'video_cutoff',
  CROSSFADE: 'crossfade',
  PLAYING: 'playing',
  PAUSED: 'paused',
  CUED: 'cued',
  ENDED: 'ended',
  BUFFERING: 'buffering',
  SHOULD_SHOW_TITLE: 'should_show_title',
  SHOULD_HIDE_TITLE: 'should_hide_title',
}
