import { Screen } from '../lib/Screen';
import { Howl } from 'howler';

import ASCII_HEADER from 'bundle-text:../../data/splash-ascii.txt';
import { BUTOTON_TEXT, SIGNATURE_TEXT } from '../../data/text-components';
import { GENERIC_EVENTS } from '../../components/event-constants';

import soundKlikPath from 'url:../../assets/klik.wav';
import soundBeepPath from 'url:../../assets/beep.wav';
import { SCREEN_NAMES } from './screens';

const BLOCKS = {
  ASCII_HEADER: 'ascii-header',
  INIT_BUTTON: 'init-button',
  ASCII_FOOTER: 'ascii-footer',
  ASCII_BOOT: 'ascii-boot',
};

export class SplashScreen extends Screen {
  constructor() {
    super(SCREEN_NAMES.SPLASH);
    this.klikSound = new Howl({ src: soundKlikPath });
    this.beepSound = new Howl({ src: soundBeepPath });

    this.init();
  }

  init() {
    this.createBlock(BLOCKS.ASCII_HEADER);
    this.addTextToBlock(BLOCKS.ASCII_HEADER, ASCII_HEADER);

    this.createBlock(BLOCKS.INIT_BUTTON);
    this.addNodeToBlock(BLOCKS.INIT_BUTTON, this.createInitButton(BUTOTON_TEXT));

    this.createBlock(BLOCKS.ASCII_FOOTER);
    this.addTextToBlock(BLOCKS.ASCII_FOOTER, SIGNATURE_TEXT);
  }

  createInitButton(text) {
    const button = document.createElement('button');
    button.className = 'launch center term-text';
    button.rel = text;
    button.addEventListener('click', () => {
      this.broadcast(GENERIC_EVENTS.INIT_APPLICATION);
    });

    return button;
  }

  addEvents() {
    this.btn.addEventListener('click', (event) => {
      this.beepSound.play();
    });

    this.btn.addEventListener('mouseenter', (event) => {
      this.klikSound.play();
    });
  }
}
