import PubSub from 'pubsub-js';

const splash = document.querySelector('#tui');
let countdownStarted = false;
let inFullScreen = false;

splash.addEventListener('mousemove', e => {
    if(!countdownStarted && inFullScreen){
        splash.classList.remove('no-cursor');
        countdownStarted = true;
        setTimeout(()=>{
            splash.classList.add('no-cursor');
            countdownStarted = false;
        }, 2000);
    }
});

document.addEventListener("keypress", function (e) {
    if (e.keyCode === 102) {
        toggleFullScreen();
    }

    if (e.keyCode === 32) {
        PubSub.publish('MUTE_PLAYER_TOGGLE');
    }
}, false);


export function toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
        inFullScreen = true;
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            inFullScreen = false;
        }
    }
}
