import { Screen } from '../lib/Screen';
import { closedOffLine, len, srep } from '../../components/utils';

import { TITLE_DISPLAY_TEXT } from '../../data/text-components';
import { SCREEN_NAMES } from './screens';
import wrap from 'word-wrap';

const BLOCKS = {
  ASCII_TITLE: 'ascii-title',
};
export class TitleScreen extends Screen {
  constructor() {
    super(SCREEN_NAMES.TITLE);
    this.init();
  }

  init() {
    this.createBlock(BLOCKS.ASCII_TITLE);
  }

  play(params) {
    const block = this.getBlock(BLOCKS.ASCII_TITLE);
    this.executeTitleDisplayScreen(block, params);
  }

  createWrappedLine(text, label) {
    const lines = wrap(text, {
      width: 36,
      indent: '│        │ '
    }).split('\n');
    lines[0] = lines[0].replace(srep(' ', len(label) + 1), ` ${label}`);
    return lines.map(line => closedOffLine(line, 48)).join('\n');
  }

  async executeTitleDisplayScreen(block, params) {
    const padWidth = 37;
    let text = TITLE_DISPLAY_TEXT.replace('$1', this.createWrappedLine(params.artist, 'Artist'));
    text = text.replace('$2', this.createWrappedLine(params.title, 'Song'));
    text = text.replace('$3', closedOffLine(params.year.toString(), padWidth));
    this.addTextToBlock(BLOCKS.ASCII_TITLE, text);
    await this.executeTextAnimation(block);
  }
}