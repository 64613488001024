import { Screen } from '../lib/Screen';

import { getProgressBarText, getRandomLoadingMessage } from '../../components/utils';
import { SCREEN_NAMES } from './screens';

const BLOCKS = {
  ASCII_LOADING: 'ascii-loading',
};

export class LoadingScreen extends Screen {
  constructor() {
    super(SCREEN_NAMES.LOADING);
    this.init();
  }

  init() {
    this.createBlock(BLOCKS.ASCII_LOADING);
  }

  play() {
    console.log('Loading screen is getting played');
    const block = this.getBlock(BLOCKS.ASCII_LOADING);
    this.executeProgressAnimation(block);
  }

  executeProgressAnimation(block) {
    let x = 0;
    let title = getRandomLoadingMessage();
    return setInterval(() => {
        if (x % 101 === 0) {
            title = getRandomLoadingMessage();
        }
        block.innerText = getProgressBarText(title, x%101);
        x++;
    }, 9);
  }
}
