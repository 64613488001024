export class ScreenManager {
  constructor(rootNode) {
    this.node = rootNode;
    this.screens = [];
  }

  add(screenNode) {
    this.screens.push(screenNode);
    this.node.appendChild(screenNode.node);
  }

  show(id) {
    this.screens.forEach(screen => {
        if(screen.id === id) {
          screen.show();
        } else {
          screen.hide();
        }
    });
  }

  getScreen(id) {
    return this.screens.find(screen => screen.id === id);
  }

  playScreen(id, payload = null) {
    return this.getScreen(id).play(payload);
  }

  createScreen(id) {
    const screenNode = document.createElement('article');
    screenNode.id = id;
    screenNode.className = 'screen';
    this.screens.push(screenNode);
  }
}
