import PubSub from 'pubsub-js';
import { TextAnim } from "../../components/text-anim";

export class Screen {
  constructor(id) {
    this.id = id;
    this.node = this._createRoot(id);
  }

  init() {
    console.log('Override this method in your classes');
  }

  show() {
    this.node.style.display = 'flex';
  }

  hide() {
    this.node.style.display = 'none';
  }

  getBlock(id) {
    return this.node.querySelector(`#${id}`);
  }

  createBlock(id) {
    const wrapper = document.createElement('article');
    wrapper.id = id;
    this.node.appendChild(wrapper);
  }

  addTextToBlock(id, text) {
    this.getBlock(id).rel = text;
  }

  addNodeToBlock(id, node) {
    const block = this.getBlock(id)
    block.classList.add('node-block');
    block.appendChild(node);
  }

  clearTextBlock(id) {
    const block = this.getBlock(id);
    block.textContent = '';
  }

  async play() {
    for (let block of this.node.querySelectorAll('article')) {
      if (block.classList.contains('node-block')) {
        const _block = this.getBlock(block.id);
        const nodeToAnim = _block.firstChild;
        await this.executeTextAnimation(nodeToAnim);
      } else {
        await this.executeTextAnimation(block);
      }
    }
  }

  executeTextAnimation(node) {
    const text = node.rel;
    return new Promise((resolve) => {
        const anim = new TextAnim(text, 25, 15);
        const timer = setInterval(() => {
            if(anim.step()) {
                node.innerText = anim.currentText;
            } else {
                clearInterval(timer);
                resolve();
            }
        }, 0);
    });
  }

  renderTemplateText(node, data = []) {
    data.forEach((item, ndx) => {
      node.rel = node.rel.replace(`$${ndx+1}`, item);
    });
  }

  /**
   * Event handling
   */
  broadcast(event, payload) {
    PubSub.publish(event, payload);
  }

  subscribe(event, callback) {
    PubSub.subscribe(event, callback);
  }

  /**
   * Creates the root node to hold screen contents
   * @param {string} id
   * @returns DomNode
   */
   _createRoot(id) {
    const wrapper = document.createElement('div');
    wrapper.id = id;
    return wrapper;
  }
}
