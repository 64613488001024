export const BUTOTON_TEXT = `+─────────────────┐
│    LAUNCH TV    │
└─────────────────┘`;

export const LOGO_TEXT = `+───────────────┐
│  HUMAN MUSIC  │
└───────────────┘`;

export const SIGNATURE_TEXT = `
Human Music TV
Built by: @tiborsaas
2022`;

export const BOOTING_TEXT = `
Booting HumanMusic OS .......................
.............................................
...........................................ok
`

export const ERROR_DISPLAY_TEXT = `
┌───────────────────────────────────────────────┐
│ Error                                         │
├───────────────────────────────────────────────┤
│ We are unable to access the requested video   │
│ Please wait till we continue with our program │
├───────────────────────────────────────────────┤
│ Reason: $1
└───────────────────────────────────────────────┘
`
// │ Remaining time: 01:34                         │

export const TITLE_DISPLAY_TEXT = `┌───────────────────────────────────────────────┐
│ Video card                                    │
╞════════╤══════════════════════════════════════╡
$1
├────────┼──────────────────────────────────────┤
$2
├────────┼──────────────────────────────────────┤
│ Year   │ $3
└────────┴──────────────────────────────────────┘
`
