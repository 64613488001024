import { Screen } from '../lib/Screen';

import { ERROR_DISPLAY_TEXT } from '../../data/text-components';
import { SCREEN_NAMES } from './screens';
import { closedOffLine } from '../../components/utils';

const BLOCKS = {
  ASCII_ERROR: 'ascii-error',
};

export class ErrorScreen extends Screen {
  constructor() {
    super(SCREEN_NAMES.ERROR);
    this.init();
  }

  init() {
    this.createBlock(BLOCKS.ASCII_ERROR);
    this.addTextToBlock(BLOCKS.ASCII_ERROR, ERROR_DISPLAY_TEXT);
  }

  play(payload) {
    const block = this.getBlock(BLOCKS.ASCII_ERROR);
    const descW = 38;
    const replacement = closedOffLine(payload, descW);
    this.renderTemplateText(block, [replacement]);
    this.executeTextAnimation(block);
  }
}
